define("moments/components/content-piece/form-field/question-matrix/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /** @type {module:app/models/form-field} */
    formField: null,

    /** @type {boolean} */
    didSubmitForm: false,

    /*------- Computed properties -------*/

    /** @type {Array.string} */
    dropdownOptions: Ember.computed.alias('formField.dropdownOptions'),

    /** @type {boolean} */
    isRadioButton: Ember.computed.equal('formField.additionalProperties.input.type', 'radio'),

    /** @type {string} */
    cellWidthClass: Ember.computed('dropdownOptions.[]', function () {
      return "width-".concat(this.get('dropdownOptions.length') + 1);
    }),

    /** @type {string} */
    fieldError: Ember.computed('formField.validations.attrs.childFormFieldValue.messages', 'didSubmitForm', function () {
      return this.get('didSubmitForm') ? this.get('formField.validations.attrs.childFormFieldValue.messages.firstObject') : null;
    }),

    /** @type {Array.<module:app/models/form-field>} */
    fields: Ember.computed('formField.childFormFields.[]', function () {
      var fields = this.get('formField.childFormFields');
      return fields ? fields.sortBy('displayOrder') : [];
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      onChange: function onChange(field, value) {
        field.set('value', value);
      }
    }
  });

  _exports.default = _default;
});