define("moments/models/session-outcome", ["exports", "ember-data", "moments/mixins/has-session-mixin"], function (_exports, _emberData, _hasSessionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend(_hasSessionMixin.default, {
    outcome: belongsTo('outcome')
  });

  _exports.default = _default;
});