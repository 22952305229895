define("moments/components/qrcode-overlay/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JhHeuDo/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"hidden\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"container \",[28,\"if\",[[24,[\"left\"]],\"left\"],null]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"inner-container\"],[8],[0,\"\\n      \"],[7,\"button\",true],[10,\"class\",\"close-button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"close\"],null]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"material-icons close-icon\"],[8],[1,[22,\"icon\"],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"preview-on-mobile\"],[8],[0,\"PREVIEW ON MOBILE\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"qr-code\"],[10,\"id\",\"qr-code\"],[8],[9],[0,\"\\n      \"],[1,[28,\"powered-by-jebbit\",null,[[\"visible\",\"campaignId\"],[true,[24,[\"campaignId\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/qrcode-overlay/template.hbs"
    }
  });

  _exports.default = _default;
});