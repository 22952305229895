define("moments/components/content-piece/form/component", ["exports", "moments/components/content-piece/base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    onClick: function onClick() {},
    contentPiece: null,
    didSubmitForm: false,
    randomizeContent: false,
    formFields: Ember.computed.alias('contentPiece.formFields'),
    sortedFormFields: Ember.computed('formFields.[]', function () {
      var formFields = this.get('formFields') || []; // child form fields will be rendered by the parent, so we don't want to have it in this list

      return formFields.filterBy('parentFormField.id', undefined).sortBy('displayOrder');
    }),
    actions: {
      click: function click() {
        var contentPiece = this.get('contentPiece');
        var fields = [];
        this.get('sortedFormFields').forEach(function (formField) {
          var type = formField.get('type');
          var validTypes = ['input', 'opt-in', 'checkbox', 'checkbox-array', 'dropdown', 'slider'];

          if (validTypes.indexOf(type) > -1) {
            var value = formField.get('isCheckbox') ? formField.get('isChecked') : formField.get('value');
            formField.set('value', value);
            fields.push(formField);
          } else if (type == 'question-matrix') {
            formField.get('childFormFields').forEach(function (child) {
              return fields.push(child);
            });
          } else if (type == 'select-all-that-apply') {
            formField.get('childFormFields').forEach(function (child) {
              child.set('value', child.get('isChecked'));
              fields.push(child);
            });
          }
        });
        this.set('didSubmitForm', true);
        this.get('onClick')(contentPiece, fields);
      },
      socialLoginClick: function socialLoginClick(formField) {
        this.get('onSocialLoginClick')(formField);
      }
    },
    contentPieceClass: Ember.computed('contentPiece.id', function () {
      return "content-".concat(this.get('contentPiece.id'));
    })
  });

  _exports.default = _default;
});