define("moments/utils/create-style-link-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createStyleLinkTag;

  function createStyleLinkTag(href) {
    var linkTag = document.createElement('link');
    linkTag.href = href;
    linkTag.rel = 'stylesheet';
    document.head.appendChild(linkTag);
  }
});