define("moments/components/powered-by-jebbit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    campaignId: null,
    left: false,
    visible: false,
    poweredByJebbitUrl: Ember.computed('campaignId', function () {
      var utmParams = ['utm_medium=web-link-experience', 'utm_campaign=poweredby-experience', "utm_source=".concat(window.location.hostname), "utm_content=".concat(this.get('campaignId'))];
      return "https://www.product.jebbit.com/simple/?".concat(utmParams.join('&'));
    })
  });

  _exports.default = _default;
});