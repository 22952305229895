define("moments/components/content-piece/form-field/select-all-that-apply/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['select-all-that-apply-wrapper'],
    classNameBindings: ['formFieldClass', 'sataClass'],

    /** @type {module:app/models/form-field} */
    formField: null,

    /** @type {boolean} */
    didSubmitForm: false,

    /*------- Computed properties -------*/

    /** @type {boolean} */
    isCheckbox: Ember.computed.equal('formField.additionalProperties.input.type', 'checkbox'),

    /** @type {boolean} */
    isButton: Ember.computed.equal('formField.additionalProperties.input.type', 'button'),

    /** @type {boolean} */
    isImageButton: Ember.computed.equal('formField.additionalProperties.input.type', 'image-button'),

    /** @type {string} */
    fieldError: Ember.computed('formField.validations.attrs.childFormFieldValue.messages', 'didSubmitForm', function () {
      return this.get('didSubmitForm') ? this.get('formField.validations.attrs.childFormFieldValue.messages.firstObject') : null;
    }),

    /** @type {Array.<module:app/models/form-field>} */
    fields: Ember.computed('formField.childFormFields.[]', function () {
      var fields = this.get('formField.childFormFields');
      return fields ? fields.sortBy('displayOrder') : [];
    }),

    /** @type {string} */
    checkboxButtonClass: Ember.computed('formField.{id,additionalProperties.input.type}', function () {
      var id = this.get('formField.id');
      var type = this.get('formField.additionalProperties.input.type');
      var baseClass = "form-field form-field-".concat(id);

      if (type == 'checkbox') {
        baseClass = "".concat(baseClass, " style-body");
      } else if (type == 'button' || type == 'image-button') {
        baseClass = "".concat(baseClass, " basic-button");
        if (type === 'button') baseClass += " style-button";
        if (type === 'image-button') baseClass += " ".concat(type, " style-image-button");
      }

      return baseClass;
    }),

    /** @type {string} */
    sataClass: Ember.computed('formField.additionalProperties.input.type', function () {
      return "sata-".concat(this.get('formField.additionalProperties.input.type'));
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      clickCheckbox: function clickCheckbox(checkbox) {
        var isAllOrNone = checkbox.get('additionalProperties.sataOptionType.type');

        if (isAllOrNone === 'all-of-the-above') {
          this.selectFields(checkbox, 'all-of-the-above');
        } else if (isAllOrNone === 'none-of-the-above') {
          this.selectFields(checkbox, 'none-of-the-above');
        } else {
          checkbox.toggleProperty('isChecked');
          checkbox.set('hasFocused', true);
          this.uncheckNecessaryFields(checkbox);
        }
      }
    },
    // -------------------------------------------------------------------------
    // Functions
    selectFields: function selectFields(checkbox, optionType) {
      var checkboxes = this.get('fields');
      var selectFunction;

      if (optionType === 'all-of-the-above') {
        selectFunction = function selectFunction(checkbox) {
          return checkbox.get('additionalProperties.sataOptionType.type') !== 'none-of-the-above';
        };
      } else {
        selectFunction = function selectFunction(checkbox) {
          return checkbox.get('additionalProperties.sataOptionType.type') === 'none-of-the-above';
        };
      }

      if (!checkbox.get('isChecked')) {
        checkboxes.forEach(function (checkbox) {
          var isChecked = selectFunction(checkbox);
          checkbox.set('isChecked', isChecked);
        });
      } else {
        checkbox.set('isChecked', false);
      }
    },
    uncheckNecessaryFields: function uncheckNecessaryFields(checkbox) {
      var checkboxes = this.get('fields');
      var noneAbove = checkboxes.findBy('additionalProperties.sataOptionType.type', 'none-of-the-above');
      var allAbove = checkboxes.findBy('additionalProperties.sataOptionType.type', 'all-of-the-above');

      if (Ember.isPresent(noneAbove) && noneAbove.get('isChecked')) {
        noneAbove.set('isChecked', false);
      } else if (Ember.isPresent(allAbove) && !checkbox.get('isChecked')) {
        allAbove.set('isChecked', false);
      }
    }
  });

  _exports.default = _default;
});