define("moments/transforms/object", ["exports", "ember-data", "jquery"], function (_exports, _emberData, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(raw) {
      return this._transformObjectKeys(raw, Ember.String.camelize);
    },
    serialize: function serialize(raw) {
      return this._transformObjectKeys(raw, Ember.String.underscore);
    },
    _transformObjectKeys: function _transformObjectKeys(raw, stringTransform) {
      if (!_jquery.default.isPlainObject(raw)) {
        return {};
      }

      return Object.keys(raw).reduce(function (object, key) {
        object[stringTransform(key)] = raw[key];
        return object;
      }, {});
    }
  });

  _exports.default = _default;
});