define("moments/services/form-branching-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getEdge: function getEdge(formFields, defaultEdge) {
      if (Ember.isEmpty(formFields)) return defaultEdge;
      var dropdownEdges = [];
      var checkboxEdges = [];
      var dropdowns = formFields.filter(function (formField) {
        return formField.get('type') == 'dropdown' && !Ember.isEmpty(formField.get('value'));
      });
      var checkboxes = formFields.filter(function (formField) {
        return formField.get('type') == 'checkbox' && formField.get('isChecked');
      });
      var addDefaultEdge = false;
      dropdowns.forEach(function (dropdown) {
        if (Ember.isEmpty(dropdown.get('edges'))) {
          addDefaultEdge = true;
          return;
        }

        var edges = dropdown.get('edges').map(function (edge) {
          if (edge.get('dropdownValues').indexOf(dropdown.get('value')) > -1) {
            return edge;
          }
        });
        edges = edges.filter(function (edge) {
          return edge;
        }); // filter null elements

        if (Ember.isEmpty(edges)) {
          addDefaultEdge = true;
        } else {
          edges.forEach(function (edge) {
            return dropdownEdges.push(edge);
          });
        }
      });
      checkboxes.forEach(function (checkbox) {
        checkbox.get('edges').forEach(function (edge) {
          return checkboxEdges.push(edge);
        });

        if (Ember.isEmpty(checkbox.get('edges'))) {
          addDefaultEdge = true;
        }
      });
      var allEdges = dropdownEdges.concat(checkboxEdges).uniq();

      if (addDefaultEdge || allEdges.length == 0) {
        allEdges.push(defaultEdge);
      }

      return allEdges.sortBy('weight').get('lastObject');
    }
  });

  _exports.default = _default;
});