define("moments/models/redirect", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    originalUrl: attr('string'),
    openInNewTab: attr('boolean'),
    forwardUrlParams: attr('boolean'),
    outcome: null,
    urlIsFinalized: false,
    url: Ember.computed('originalUrl', 'outcome.{completionUrl,link}', 'isDynamicUrl', function () {
      var outcomeUrl = this.get('outcome.completionUrl');

      if (outcomeUrl && this.get('isDynamicUrl')) {
        return outcomeUrl;
      } else {
        return this.get('originalUrl');
      }
    }),
    isDynamicUrl: Ember.computed('originalUrl', function () {
      var originalUrl = this.get('originalUrl');
      return originalUrl === '{ outcome url }' || originalUrl === '{{link}}';
    })
  });

  _exports.default = _default;
});