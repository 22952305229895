define("moments/components/layouts/inner-content-scrollable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AiA1DZB5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"content-block-inner scrollable \",[22,\"layoutClass\"],\" \",[22,\"deviceType\"]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"scrollable-content \",[22,\"deviceType\"]]]],[8],[0,\"\\n    \"],[1,[28,\"layouts/inner-content\",null,[[\"campaign\",\"contentBlock\",\"deviceType\",\"layoutClass\",\"logo\",\"showClassName\",\"userHasResponded\",\"buttonWasClicked\",\"didShare\",\"socialLoginSubmit\",\"submitForm\"],[[24,[\"campaign\"]],[24,[\"contentBlock\"]],[24,[\"deviceType\"]],[24,[\"layoutClass\"]],[24,[\"logo\"]],false,[24,[\"userHasResponded\"]],[28,\"action\",[[23,0,[]],\"buttonWasClicked\"],null],[28,\"action\",[[23,0,[]],\"didShare\"],null],[28,\"action\",[[23,0,[]],\"socialLoginSubmit\"],null],[28,\"action\",[[23,0,[]],\"submitForm\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/layouts/inner-content-scrollable/template.hbs"
    }
  });

  _exports.default = _default;
});