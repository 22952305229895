define("moments/instance-initializers/js-hooks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function initialize()
  /* appInstance */
  {
    var jsHooks = {
      q: [],
      enqueue: function enqueue() {
        this.q.push(Array.prototype.slice.call(arguments));
      },
      handleQ: function handleQ() {
        while (this.q.length > 0) {
          var args = this.q.shift();
          var hookName = args.shift();
          this.run.apply(this, [hookName].concat(_toConsumableArray(args)));
        }
      },
      run: function run(hookName) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        if (!this.hooks) {
          // Attempt to use legacy js hooks if available
          if (!window.jebbit_hooks) {
            this.enqueue.apply(this, arguments);
            return;
          }

          this.hooks = window.jebbit_hooks;
        }

        var fn = this.hooks[hookName];

        if (typeof fn === 'function') {
          try {
            fn.apply(this, args);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Custom JS threw an error: ".concat(hookName), e);
          }
        } else {
          // eslint-disable-next-line no-console
          console.info("Custom JS is not defined: ".concat(hookName));
        }
      }
    };

    if (!window.jebbitJSHooks) {
      window.jebbitJSHooks = jsHooks;
    } else {
      Ember.assign(window.jebbitJSHooks, jsHooks);
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});