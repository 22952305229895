define("moments/models/edge-outcome", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    edge: belongsTo('edge'),
    formField: belongsTo('formField'),
    outcome: belongsTo('outcome'),
    pointValue: attr('number'),
    rule: attr('string'),
    ruleValue: attr('array')
  });

  _exports.default = _default;
});