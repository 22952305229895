define("moments/components/progress-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*------------ Variables ------------*/
    classNames: ['progress-bar-container'],
    currentValue: null,
    maxValue: null,

    /*------- Computed properties -------*/
    widthPercentage: Ember.computed('currentValue', 'maxValue', function () {
      var percentage = Math.round(this.get('currentValue') / this.get('maxValue') * 100);

      if (percentage > 100) {
        percentage = 100;
      } else if (percentage < 0) {
        percentage = 0;
      }

      return Ember.String.htmlSafe(percentage);
    })
  });

  _exports.default = _default;
});