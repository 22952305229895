define("moments/models/campaign", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend({
    campaignLogo: belongsTo('campaign-logo'),
    contentCollections: hasMany('content-collection'),
    css: attr('string'),
    cssUrl: attr('string'),
    iterationId: attr('string'),
    jsUrl: attr('string'),
    maxDepth: attr('number'),
    nodes: hasMany('node'),
    outcomeGroup: belongsTo('outcome-group'),
    pixels: hasMany('pixel'),
    rootNode: belongsTo('node'),
    sessionTtl: attr(),
    showPoweredByJebbit: attr('boolean'),
    templateVersion: attr('string'),
    transition: attr('string')
  });

  _exports.default = _default;
});