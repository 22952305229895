define("moments/components/content-piece/form-field/select-all-that-apply/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p5QHY+wX",
    "block": "{\"symbols\":[\"field\",\"i\"],\"statements\":[[4,\"each\",[[24,[\"fields\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"layout-row flex  \",[28,\"if\",[[28,\"or\",[[24,[\"isButton\"]],[24,[\"isImageButton\"]]],null],\"button-wrapper\",\"\"],null]]]],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"id\",\"type\",\"class\",\"checked\",\"click\"],[[23,1,[\"inputId\"]],\"checkbox\",\"form-field-checkbox\",[23,1,[\"isChecked\"]],[28,\"action\",[[23,0,[]],\"clickCheckbox\",[23,1,[]]],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"isCheckbox\"]]],null,{\"statements\":[[0,\"      \"],[7,\"label\",false],[12,\"class\",[29,[[22,\"checkboxButtonClass\"],\" \",[23,1,[\"additionalProperties\",\"sataOptionType\",\"type\"]]]]],[12,\"for\",[29,[[23,1,[\"inputId\"]]]]],[3,\"action\",[[23,0,[]],\"clickCheckbox\",[23,1,[]]]],[8],[0,\"\\n        \"],[1,[23,1,[\"text\"]],true],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"isButton\"]],[24,[\"isImageButton\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",[29,[[28,\"concat\",[[24,[\"checkboxButtonClass\"]],\" sata-\",[23,1,[\"id\"]],\" sata-\",[23,2,[]]],null],\" \",[23,1,[\"additionalProperties\",\"sataOptionType\",\"type\"]]]]],[3,\"action\",[[23,0,[]],\"clickCheckbox\",[23,1,[]]]],[8],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[\"content \",[22,\"contentClass\"]]]],[8],[0,\"\\n          \"],[1,[23,1,[\"text\"]],true],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[7,\"div\",true],[10,\"class\",\"paper-input-error\"],[8],[1,[22,\"fieldError\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/form-field/select-all-that-apply/template.hbs"
    }
  });

  _exports.default = _default;
});