define("moments/components/layouts/inner-content-scrollable/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inner-content-wrapper-scrollable'],
    // -------------------------------------------------------------------------
    // Actions
    buttonWasClicked: function buttonWasClicked() {},
    didShare: function didShare() {},
    socialLoginSubmit: function socialLoginSubmit() {},
    submitForm: function submitForm() {},
    actions: {
      buttonWasClicked: function buttonWasClicked(contentPiece) {
        this.get('buttonWasClicked')(contentPiece);
      },
      didShare: function didShare() {
        this.get('didShare')();
      },
      socialLoginSubmit: function socialLoginSubmit() {
        this.get('socialLoginSubmit')();
      },
      submitForm: function submitForm(contentPiece, formSubmission) {
        this.get('submitForm')(contentPiece, formSubmission);
      }
    }
  });

  _exports.default = _default;
});