define("moments/components/powered-by-jebbit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vJzOThm0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"visible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[22,\"poweredByJebbitUrl\"]],[10,\"target\",\"_blank\"],[11,\"class\",[29,[\"powered-by-jebbit-button \",[28,\"if\",[[24,[\"left\"]],\"left\"],null]]]],[8],[0,\"Powered by \"],[7,\"span\",true],[8],[0,\"jebbit\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/powered-by-jebbit/template.hbs"
    }
  });

  _exports.default = _default;
});