define("moments/components/content-piece/container/component", ["exports", "moments/components/content-piece/base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    onClick: function onClick() {},
    onFormSubmit: function onFormSubmit() {},
    onShare: function onShare() {},
    onSocialLoginClick: function onSocialLoginClick() {},
    actions: {
      buttonWasClicked: function buttonWasClicked(contentPiece) {
        this.get('onClick')(contentPiece);
      },
      didShare: function didShare() {
        this.get('onShare').apply(void 0, arguments);
      },
      socialLoginClick: function socialLoginClick() {
        this.get('onSocialLoginClick').apply(void 0, arguments);
      },
      submitForm: function submitForm(contentPiece, formSubmission) {
        this.get('onFormSubmit')(contentPiece, formSubmission);
      }
    },
    childContentPieces: Ember.computed.alias('contentPiece.childContentPieces'),
    isFluid: Ember.computed.alias('contentPiece.jsonValue.isFluid'),
    columns: Ember.computed('childContentPieces.[]', 'contentPiece.jsonValue.numColumns', function () {
      var childContentPieces = this.get('childContentPieces') || [];
      var numColumns = this.get('contentPiece.jsonValue.numColumns') || 1;
      var columns = {};

      for (var i = 0; i < numColumns; i++) {
        columns["column_".concat(i)] = [];
      }

      childContentPieces.forEach(function (contentPiece) {
        var layoutPartName = contentPiece.get('layoutPartName');
        var column = columns[layoutPartName];

        if (column) {
          column[contentPiece.get('displayOrder')] = contentPiece;
        }
      });
      Object.keys(columns).forEach(function (key) {
        columns[key] = columns[key].compact();
      });
      return columns;
    })
  });

  _exports.default = _default;
});