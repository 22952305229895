define("moments/models/edge", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    weight: attr('number'),
    dropdownValues: attr('array'),
    contentPiece: belongsTo('content-piece'),
    childNode: belongsTo('node'),
    edgeOutcomes: hasMany('edge-outcome'),
    parentNode: belongsTo('node'),
    formFields: hasMany('form-field')
  });

  _exports.default = _default;
});