define("moments/services/edge-message-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notifications: Ember.inject.service(),
    overlayScreenManager: Ember.inject.service(),
    getEdgeMessage: function getEdgeMessage(contentPiece) {
      if (!contentPiece) return;
      return contentPiece.get('edgeMessage.content');
    },
    showEdgeMessage: function showEdgeMessage(edgeMessage) {
      var layout = edgeMessage.get('layout');

      if (layout === 'Answer Bar') {
        this.handleFlashMessage(edgeMessage);
      } else if (layout === 'Full Screen') {
        this.handleOverlay(edgeMessage);
      }
    },
    handleFlashMessage: function handleFlashMessage(edgeMessage) {
      if (!edgeMessage.get('text')) {
        return;
      }

      var _edgeMessage$getPrope = edgeMessage.getProperties('icon', 'text'),
          icon = _edgeMessage$getPrope.icon,
          text = _edgeMessage$getPrope.text;

      var types = {
        check: 'success',
        clear: 'error',
        'thumb up': 'success',
        'thumb down': 'error'
      };
      var notification = this.get('notifications').addNotification({
        autoClear: true,
        clearDuration: edgeMessage.get('durationSeconds'),
        message: text,
        type: types[icon] || 'info'
      });
      notification.set('icon', icon);
    },
    handleOverlay: function handleOverlay(edgeMessage) {
      var options = {
        duration: edgeMessage.get('duration'),
        color: edgeMessage.get('color'),
        icon: edgeMessage.get('icon'),
        text: edgeMessage.get('text')
      };
      this.get('overlayScreenManager').show(options);
    }
  });

  _exports.default = _default;
});