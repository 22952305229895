define("moments/components/qrcode-overlay/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    campaignId: null,
    hidden: true,
    left: false,
    icon: 'close',
    didRender: function didRender() {
      var typeNumber = 6;
      var errorCorrectionLevel = 'L';
      var scriptUrl = 'https://cdnjs.cloudflare.com/ajax/libs/qrcode-generator/1.4.4/qrcode.min.js';

      _jquery.default.getScript(scriptUrl).then(Ember.run.bind(function () {
        // eslint-disable-next-line no-undef
        var qr = qrcode(typeNumber, errorCorrectionLevel);
        qr.addData(window.location.href);
        qr.make();
        document.getElementById('qr-code').innerHTML = qr.createImgTag(3, 0);
      }));
    },
    onClose: function onClose() {},
    actions: {
      close: function close() {
        this.onClose();
      }
    }
  });

  _exports.default = _default;
});