define("moments/utils/create-style-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createStyleTag;

  function createStyleTag(css) {
    var styleTag = document.createElement('style');
    styleTag.type = 'text/css';
    styleTag.innerHTML = css;
    styleTag.id = 'experience-style';
    document.head.appendChild(styleTag);
  }
});